import api from "@/api";
import Pagination from "@/components/Pagination";
import { PaginationRow, TableRow } from "@/models/type";
import { parseTime } from "@/utils";
import { Prop, Vue } from "vue-property-decorator";

export default class OrdinarySign extends Vue {

  @Prop({ type: Number, default: 0 }) readonly id!: number
  public type = 'subject'


  private tableData: Array<any> = []
  private total = 0
  public tableListSubject: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any) => <span>{scope.$index + 1}</span>
    } },
    { label: '时间', slot: {
      default: (scope: any) => <span>{parseTime(new Date(scope.row.create_time).getTime(), '{y}.{m}.{d} {h}:{i}:{s}')}</span>
    } },
    { label: '属性', slot: {
      default: () => <span>{ this.type === 'subject' ? '课程' : '活动' }</span>
    } },
    { label: '名称', prop: 'title' },
    { label: '报名时间', slot: {
      default: (scope: any) => <span>{parseTime(new Date(scope.row.subjectStartTime).getTime(), '{y}.{m}.{d} {h}:{i}:{s}')}</span>
    } },
    { label: '签到小节', prop:  'chapter' },
    { label: '支付金额', prop: 'payPrice' },
  ]
  public tableListActivity: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any) => <span>{scope.$index + 1}</span>
    } },
    { label: '时间', slot: {
      default: (scope: any) => <span>{parseTime(new Date(scope.row.create_time).getTime(), '{y}.{m}.{d} {h}:{i}:{s}')}</span>
    } },
    { label: '属性', slot: {
      default: () => <span>{ this.type === 'subject' ? '课程' : '活动' }</span>
    } },
    { label: '名称', prop: 'title' },
    { label: '地址', prop: 'position'},
    { label: '报名时间', slot: {
      default: (scope: any) => <span>{parseTime(new Date(scope.row.create_time).getTime(), '{y}.{m}.{d} {h}:{i}:{s}')}</span>
    } },
    { label: '是否参加', prop:  'chapter' },
    { label: '支付金额', prop: 'payPrice' },
  ]

  private tableList: Array<TableRow> = this.tableListSubject

  private getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
    api.teamGetSignUpRecord({ pageNum, pageSize, id: this.id, type: this.type }).then(res => {
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }

  private tableDom(): JSX.Element {
    return <el-table
      data={ this.tableData }
    >
      {this.tableList.map(item => {
        return <el-table-column
          label={ item.label }
          prop={ item.prop }
          align="center"
          v-slots={ item.slot }
        />
      })}
    </el-table>
  }

  public getTypeList(type: string): void {
    this.type = type
    this.tableList = this.type === 'subject' ? this.tableListSubject : this.tableListActivity
    this.getList()
  }

  render(): JSX.Element {
    return <div>
      <div style="display:flex; justify-content: flex-end;">
        <div class="ordinary-choose">
          <div class={this.type === 'subject' ? 'active' : ''} onClick={() => this.getTypeList('subject')}>课程</div>
          <div class={this.type === 'activity' ? 'active' : ''} onClick={() => this.getTypeList('activity')}>活动</div>
        </div>
      </div>
      {this.tableDom()}
      <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
    </div>
  }
  mounted(): void {
    this.getList()
  }
}
