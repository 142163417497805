import Tabs from "@/components/Tabs";
import { TabListRow } from "@/models/type";
import { ElCard, ElPageHeader } from "element-plus";
import { Vue } from "vue-class-component";
import { h } from "vue";
import MoreVipCommon from "./components/moreVipCommon";
import MoreVipSign from "./components/moreVipSign";
import MoreVipPay from "./components/moreVipPay";
import MoreVip from ".";

/**
 * @description 普通账户详情
 * @author Jinx
 * @date 2021-09-02 10:30:20
 * @export
 * @class OrdinaryDetail
 * @extends {Vue}
 */
export default class OrdinaryDetail extends Vue {

  private activeName = 'common'
  private tabList: Array<TabListRow> = [
    { title: '基础信息', activeName: 'common' },
    { title: '报名记录', activeName: 'sign' },
    { title: '购买记录', activeName: 'pay' }
  ]

  activechange(activeName: string): void {
    this.activeName = activeName
  }

  detailDom(): JSX.Element {

    const __parent = this.$parent as MoreVip

    const slots = {
      common: () => h(MoreVipCommon, {
        id: __parent.detailId
      }),
      sign: () => h(MoreVipSign, {
        id: __parent.detailId
      }),
      pay: () => h(MoreVipPay, {
        id: __parent.detailId
      })
    }

    return h(Tabs, {
      tabList: this.tabList,
      activeName: this.activeName,
      onActivechange: this.activechange
    }, slots)
  }

  goBack(): void {
    const __parent = this.$parent as MoreVip
    __parent.type = ''
  }

  backDom(): JSX.Element {
    return h(ElPageHeader, {
      title: '成员详情',
      onBack: this.goBack
    })
  }

  render(): JSX.Element {
    return h('div', {
      class: 'ordinary'
    }, [
      h(ElCard, {
        class: 'ordinary-detail',
      }, this.backDom),
      h(ElCard, {
        class: 'ordinary-detail',
      }, this.detailDom)
    ])
  }
}
