import api from "@/api";
import { TableRow } from "@/models/type";
import { parseTime } from "@/utils";
import { Prop, Vue } from "vue-property-decorator";

export default class OrdinaryCommon extends Vue {
  @Prop({ type: Number, default: 0 }) readonly id!:number
  private commonList: Array<TableRow> = [
    { label: '用户编号', prop: 'memberId' },
    { label: '注册时间', prop: 'createTime' },
    { label: '账户状态', prop: 'status' },
    { label: '姓名', prop: 'username' },
    { label: '性别', prop: 'gender' },
    { label: '身份证号', prop: 'cardId' },
    { label: '手机号', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
    { label: '微信', prop: 'wechat' },
    { label: 'Facebook', prop: 'facebook' },
    { label: 'Google', prop: 'google' },
    { label: 'Instagram', prop: 'instagram' },
  ]

  private commonForm: any = {}

  getInfo(): void {
    api.teamGetMemberById(this.id).then(res => {
      const {member, social} = res.data
      member.createTime = parseTime(new Date(member.createTime).getTime())
      member.status = member.status ? '正常' : '异常'
      member.gender = member.gender === 1 ? '男' : '女'
      const obj = Object.assign({}, member)
      social.forEach(item => {
        obj[item.source] = item.account
      })
      this.commonForm = obj
    })
  }

  render(): JSX.Element {
    return <el-row>
      {this.commonList.map(item => <el-col
          lg={ 8 }
          sm={ 24 }
        >
          <span class="ordinary_common-label">{ item.label }：</span>
          <span class="ordinary_common-value">{ this.commonForm[item.prop as string] }</span>
        </el-col>
      )}
    </el-row>
  }

  mounted(): void {
    this.getInfo()
  }
}
