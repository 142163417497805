import { ElCard, ElMessageBox, ElNotification, ElPageHeader } from "element-plus";
import { Vue, Watch } from "vue-property-decorator";
import { h } from 'vue'
import MoreVip from ".";
import { SearchListRow, TableRow } from "@/models/type";
import api from "@/api";
import { UmsMemberParam, UmsTeamParam } from "@/models/team";
import { parseTime } from "@/utils";

export default class TeamDetail extends Vue {

  changeId = 0
  public parentId = 0

  @Watch('changeId')
  changeIdAdd(num: number): void {
    if(num) {
      const __parent = this.$parent as MoreVip
      __parent.detailId = this.parentId
      __parent.type = 'detail'
    }
  }

  commonList: Array<SearchListRow> = [
    { label: '用户编号', submitKey: 'id', type: 'span' },
    { label: '注册时间', submitKey: 'createTime', type: 'time' },
    { label: '账户状态', submitKey: 'status', type: 'switch' },
    { label: '团队名称', submitKey: 'title', type: 'span' },
    { label: '账号限制', submitKey: 'accountNumber', type: 'input', placeholder: '请输入账号限制' },
    { label: '团队地址', submitKey: 'address', type: 'input', placeholder: '请输入团队地址' },
    { label: '姓名', submitKey: 'leaderName', type: 'input', placeholder: '请输入姓名' },
    { label: '性别', submitKey: 'gender', type: 'select', options: [{name: '男', value: 1}, {name: '女', value: 2}], placeholder: '请选择性别' },
    { label: '身份证', submitKey: 'cardId', type: 'input', placeholder: '请输入身份证号' },
    { label: '手机号', submitKey: 'phone', type: 'input', placeholder: '请输入手机号' },
    { label: '邮箱', submitKey: 'email', type: 'input', placeholder: '请输入邮箱' }
  ]

  private teamClientList: Array<TableRow> = [
    { label: '会员编号', prop: 'id' },
    { label: '成员姓名', prop: 'username' },
    { label: '性别', slot: {
      default: (scope: any) => <span>{scope.row.gender === 1 ? '男' : '女'}</span>
    } },
    { label: '手机号', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
    { label: '身份证', prop: 'cardId' },
    { label: '操作', slot: {
      default: (scope: any) => {
        return <>
          <span class="span-button-danger" onClick={ () => this.resPas(scope.row.id) }>修改密码</span>
          <span class="span-button-primary" onClick={ () => this.teamDetail(scope.row.id) }>查看</span>
        </>
      }
    } }
  ]

  commonForm: UmsTeamParam = {}
  teamClientData: Array<UmsMemberParam> = []

  teamDetail(id: number): void {
    this.parentId = id
    this.changeId++
  }

  resPas(id: number): void {
    ElMessageBox.prompt('请输入密码', '修改密码', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    }).then(({value}) => {
      api.teamEditMemberPassword({
        id,
        password: value
      }).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '修改成功'
        })
      })
    }).catch(() => false)
  }


  backDom(): JSX.Element {
    return h(ElPageHeader, {
      title: '团队详情',
      onBack: this.goBack
    })
  }

  goBack(): void {
    const __parent = this.$parent as MoreVip
    __parent.type = ''
    __parent.getList()
  }

  detailDom(): JSX.Element {
    return <ElCard
      class="ordinary-detail"
    >
      <div class="ordinary-detail-title">团队信息</div>
      <div class="ordinary-detail-content">
        <el-row gutter={40}>
          {this.commonList.slice(0, 6).map(item => <el-col
              lg={ 8 }
              sm={ 24 }
            >
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <span class="ordinary_common-label">{ item.label }：</span>
                {(() => {
                  switch (item.type) {
                    case 'span':
                      return <span class="ordinary_common-value">{ this.commonForm[item.submitKey as string] }</span>
                    case 'time':
                      return <span class="ordinary_common-value">
                        { parseTime(new Date(this.commonForm[item.submitKey as string]).getTime()) }
                      </span>
                    case 'input':
                      return <el-input
                        v-model={this.commonForm[item.submitKey as string]}
                        style="width: 60%"
                        clearable
                        placeholder={item.placeholder}
                      />
                    case 'select':
                      return <el-select
                        v-model={this.commonForm[item.submitKey as string]}
                        style="width: 60%"
                        clearable
                        placeholder={item.placeholder}
                        filterable
                      >
                        {item.options?.map((i:any) => (
                          <el-option
                            key={ i.value }
                            label={ i.name }
                            value={ i.value }
                          />
                        ))}
                      </el-select>
                    case 'switch':
                      return <el-switch
                        v-model={this.commonForm[item.submitKey as string]}
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-value={1}
                        inactive-value={0}
                      >
                      </el-switch>
                    default:
                      break;
                  }
                })()}
              </div>
            </el-col>
          )}
        </el-row>
      </div>
      <div class="ordinary-detail-title">团队负责人信息</div>
      <div class="ordinary-detail-content">
      <el-row gutter={40}>
        {this.commonList.slice(6).map(item => <el-col
              lg={ 8 }
              sm={ 24 }
            >
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <span class="ordinary_common-label">{ item.label }：</span>
                {(() => {
                  switch (item.type) {
                    case 'span':
                      return <span class="ordinary_common-value">{ this.commonForm[item.submitKey as string] }</span>
                    case 'time':
                      return <span class="ordinary_common-value">
                        { parseTime(new Date(this.commonForm[item.submitKey as string]).getTime()) }
                      </span>
                    case 'input':
                      return <el-input
                        v-model={this.commonForm[item.submitKey as string]}
                        style="width: 60%"
                        clearable
                        placeholder={item.placeholder}
                      />
                    case 'select':
                      return <el-select
                        v-model={this.commonForm[item.submitKey as string]}
                        style="width: 60%"
                        clearable
                        placeholder={item.placeholder}
                        filterable
                      >
                        {item.options?.map((i:any) => (
                          <el-option
                            key={ i.value }
                            label={ i.name }
                            value={ i.value }
                          />
                        ))}
                      </el-select>
                    default:
                      break;
                  }
                })()}
              </div>
            </el-col>
          )}
        </el-row>
      </div>
      <div class="ordinary-detail-btn">
        <div onClick={this.teamDelete}>删除</div>
        <div onClick={this.submit}>保存</div>
      </div>
    </ElCard>
  }

  listDom(): JSX.Element {
    return <el-table
      data={this.teamClientData}
    >
      {this.teamClientList.map(i => (
          <el-table-column
            prop={ i.prop }
            label={ i.label }
            align={ i.align || 'center' }
            v-slots={ i.slot }
          />
        ))}
    </el-table>
  }

  teamDelete(): void {
    const __parent = this.$parent as MoreVip
    ElMessageBox.confirm('确定删除该团队？', '删除团队', {
      type: 'warning'
    }).then(() => {
      api.teamDeleteTeam([__parent.detailId]).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '删除成功'
        })
        this.goBack()
      })
    }).catch(() => false)
  }

  submit(): void {
    const obj = Object.assign({}, this.commonForm)
    api.teamEditTeamInfo(obj).then(() => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '修改成功'
      })
      this.goBack()
    })
  }

  getDetail(): void {
    const __parent = this.$parent as MoreVip
    api.teamGetTeamAccountById({ id: __parent.detailId }).then(res => {
      this.commonForm = res.data.umsTeam
      this.teamClientData = res.data.umsMember
    })
  }

  render(): JSX.Element {
    return <div
      class="ordinary"
    >
      <ElCard
        class="ordinary-detail"
      >
        {this.backDom()}
      </ElCard>
      {this.detailDom()}
      <ElCard
        class="ordinary-detail"
      >
        <div class="ordinary-detail-title">团队成员</div>
        {this.listDom()}
      </ElCard>
    </div>
  }

  mounted(): void {
    this.getDetail()
  }
}
