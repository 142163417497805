import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import Switch from "@/components/Switch";
import { UmsTeamParam } from "@/models/team";
import { ButtonListRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { debounceClick, typeWatch } from "@/utils";
import { isEmail } from "@/utils/validate";
import { ElMessageBox, ElNotification } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { Vue, Watch } from "vue-property-decorator";
import MoreVipDetail from "./detail";
import './index.scss'
import TeamDetail from "./teamDetail";

export default class MoreVip extends Vue {
  private total = 0

  public detailBool = false
  public switchNum = 0

  private title = ''
  public type = ''
  public detailId = 0
  private searchList: Array<SearchListRow> = [
    { label: '会员编号', type: 'input', submitKey: 'id', placeholder: '请输入会员编号' },
    { label: '姓名', type: 'input', submitKey: 'name', placeholder: '请输入姓名' },
    { label: '身份证号', type: 'input', submitKey: 'cardId', placeholder: '请输入身份证号' },
    { label: '手机号', type: 'input', submitKey: 'phone', placeholder: '请输入手机号' },
    { label: '邮箱', type: 'input', submitKey: 'email', placeholder: '请输入邮箱' },
    { label: '团体名称', type: 'input', submitKey: 'title', placeholder: '请输入团体名称' }
  ]
  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'moreVipAdd' },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'moreVipDelete' },
  ]

  private tableData: Array<any> = []
  private teamClientData: Array<any> = []

  private tableList: Array<TableRow> = [
    { label: '会员编号', prop: 'id' },
    { label: '团体名称', prop: 'title' },
    { label: '负责人姓名', prop: 'leaderName' },
    { label: '性别', slot: {
      default: (scope: any) => <span>{scope.row.gender === 1 ? '男' : '女'}</span>
    } },
    { label: '手机号', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
    { label: '身份证', prop: 'cardId' },
    { label: '账户状态', slot: {
      default: (scope: any) => <Switch switchBool={ scope.row.status } bindValue={{ open: 1, close: 0 }} onSwitch={val => this.switchClick(val, scope.row.id)} />
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <span class="span-button-primary" onClick={ () => this.detail(scope.row.id)} >详情</span>
    } }
  ]
  private teamClientList: Array<TableRow> = [
    { label: '会员编号', prop: 'id' },
    { label: '成员姓名', prop: 'username' },
    { label: '性别', slot: {
      default: (scope: any) => <span>{scope.row.gender === 1 ? '男' : '女'}</span>
    } },
    { label: '手机号', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
    { label: '身份证', prop: 'cardId' },
    { label: '操作', slot: {
      default: (scope: any) => scope.row.loading
        ? <span class="el-icon-loading" />
        : <span class="span-button-primary" onClick={ () => this.teamDetail(scope.row.id) }>详情</span>
    } }
  ]
  private searchObj: any = {}
  public expands: Array<any> = []

  public codeValidate = (rules, value, cb): void => {
    if(!value) {
      cb(new Error('请输入团队代码'))
    } else if (!/^[A-Z]{2}$/.test(value)) {
      cb(new Error('团队代码只能为两位大写英文字母'))
    } else {
      cb()
    }
  }
  public phoneValidate = (rules, value, cb): void => {
    if(!value) {
      cb(new Error('请输入手机号'))
    } else if (!(value.length > 6)) {
      cb(new Error('手机号不能少于6位数'))
    } else {
      cb()
    }
  }
  public emailValidate = (rules, value, cb): void => {
    if(!value) {
      cb(new Error('请输入邮箱'))
    } else if (!isEmail(value)) {
      cb(new Error('请输入正确邮箱'))
    } else {
      cb()
    }
  }

  public teamForm: UmsTeamParam = {
    accountNumber: 0,
    address: '',
    cardId: '',
    code: '',
    email: '',
    gender: 1,
    leaderName: '',
    phone: '',
    title: '',
    memberLevelId: ''
  }

  public formRules: any = {
    title: [
      { required: true, message: '请输入团队名称' }
    ],
    leaderName: [
      { required: true, message: '请输入负责人姓名' }
    ],
    code: [
      { required: true, validator: this.codeValidate }
    ],
    phone: [
      { required: true, validator: this.phoneValidate }
    ],
    email: [
      { required: true, validator: this.emailValidate }
    ]
  }
  public ids: Array<number> = []

  teamDetail(id: number): void {
    this.detailId = id
    this.type = 'detail'
  }

  /**
   * @description 启用 停用
   * @author Jinx
   * @date 2021-10-21 09:18:11
   * @param {*} status
   * @param {*} ids
   * @memberof MoreVip
   */
  switchClick(status, ids): void {
    api.teamSetTeamStatus(
      [ids],
      {status}
    ).then(() => {
      ElNotification({
        type: 'success',
        message: '操作成功'
      })
      this.switchNum++
    })
  }

  /**
   * @description 团队详情
   * @author Jinx
   * @date 2021-11-15 09:27:40
   * @param {number} id
   * @memberof MoreVip
   */
  detail(id: number): void {
    this.detailId = id
    this.type = 'see'
  }

  @Watch('switchNum')
  switchChange(val: number): void {
    if(val) {
      this.getList()
    }
  }

  @Watch('type')
  typeChange(val: string): void {
    this.title = typeWatch(val)
  }

  moreVipAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  moreVipDelete(): void {
    if(!(this.ids.length)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的团队列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该团队成员？', '删除', {
      type: 'warning'
    }).then(() => {
      api.teamDeleteTeam(this.ids).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '删除成功'
        })
        this.getList()
      })
    }).catch(() => false)
  }

  onSearch(obj: never): void {
    this.searchObj = Object.assign({}, obj)
    this.getList()
  }

  buttonClick(click: string): void {
    this[click]()
  }

  selectionChange(row: Array<UmsTeamParam>): void {
    this.ids = row.map(item => item.id) as number []
  }

  expandChange(row: UmsTeamParam, expanded: Array<UmsTeamParam>): void {
    if (expanded.length) {
      this.expands = []
      if (row) {
        this.teamClientData = []
        api.teamGetMemberInfoByTeamId({id: row.id as number}).then(res => {
          this.teamClientData = res.data
        })
        this.expands.push(row.id)
      }
    } else {
      this.expands = []
    }
  }

  tableDom(): JSX.Element {

    const slot = {
      default: () => <el-table
        data={this.teamClientData}
      >
        {this.teamClientList.map(i => (
            <el-table-column
              prop={ i.prop }
              label={ i.label }
              align={ i.align || 'center' }
              v-slots={ i.slot }
            />
          ))}
      </el-table>
    }

    return <el-table
      data={ this.tableData }
      rowKey={ this.getRowKeys }
      expand-row-keys={this.expands}
      onSelectionChange = { this.selectionChange }
      onExpandChange={this.expandChange}
    >
      <el-table-column type="selection" width="50px" />
      <el-table-column type="expand" v-slots={slot} />
      {this.tableList.map(item => (
        <el-table-column
          label={ item.label }
          align="center"
          prop={ item.prop }
          v-slots={ item.slot }
        />
      ))}
    </el-table>
  }

  getRowKeys(row): number {
    return row.id
  }

  getList(): void {
    this.tableData = []
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
      api.teamGetTeamList({pageNum, pageSize, ...this.searchObj}).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    })
  }
  detailClose(): void {
    this.detailBool = false
    this.teamForm = {
      accountNumber: 0,
      address: '',
      cardId: '',
      code: '',
      email: '',
      gender: 1,
      leaderName: '',
      phone: '',
      title: '',
      memberLevelId: ''
    }
  }
  submit(): void {
    const __form = this.$refs.form as ElFormItemContext
    __form.validate(v => {
      if(v) {
        const obj = Object.assign({}, this.teamForm) as UmsTeamParam
        this.type === 'add'
          ? this.submitAdd(obj)
          : this.submitEdit(obj)
      } else {
        return false
      }
    })
  }
  submitAdd(obj: UmsTeamParam): void {
    api.teamAddTeam(obj).then(res => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '操作成功'
      })
      this.detailClose()
      this.getList()
    })
  }
  submitEdit(obj: UmsTeamParam): void {
    api.teamEditTeamInfo(obj).then(res => {
      console.log(res)
    })
  }
  dialogDom(): JSX.Element {
    const slots = {
      // 对话框底部元素
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose }>取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return <el-dialog
      title="添加团体会员管理账号"
      v-model={ this.detailBool }
      width="50%"
      before-close={ this.detailClose }
      v-slots={ slots }
      custom-class="resource-dialog"
    >
      <el-form
        ref="form"
        model={ this.teamForm }
        rules={ this.formRules }
        label-width="130px"
        class="el-form-tao"
      >
        <el-row gutter={30}>
          <el-col span={12}>
            <el-form-item label="团队名称" prop="title">
              <el-input v-model={ this.teamForm.title } placeholder="请输入团队名称" clearable />
            </el-form-item>
          </el-col>
          <el-col span={12}>
            <el-form-item label="团队代码" prop="code">
              <el-input v-model={ this.teamForm.code } placeholder="请输入团队代码" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row gutter={30}>
          <el-col span={12}>
            <el-form-item label="团体地址" prop="address">
              <el-input v-model={ this.teamForm.address } placeholder="请输入团体地址" clearable />
            </el-form-item>
          </el-col>
          <el-col span={12}>
            <el-form-item label="账号限制" prop="accountNumber">
              <el-input v-model={ this.teamForm.accountNumber } placeholder="请输入账号限制" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row gutter={30}>
          <el-col span={12}>
            <el-form-item label="会员编码" prop="id">
              <el-input v-model={ this.teamForm.id } disabled placeholder="会员编码自动生成" clearable />
            </el-form-item>
          </el-col>
          <el-col span={12}>
            <el-form-item label="负责人姓名" prop="leaderName">
              <el-input v-model={ this.teamForm.leaderName } placeholder="请输入负责人姓名" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row gutter={30}>
          <el-col span={12}>
            <el-form-item label="性别" prop="gender">
            <el-radio-group v-model={this.teamForm.gender}>
              <el-radio label={1}>男</el-radio>
              <el-radio label={2}>女</el-radio>
            </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col span={12}>
            <el-form-item label="身份证" prop="cardId">
              <el-input v-model={ this.teamForm.cardId } placeholder="请输入身份证" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row gutter={30}>
          <el-col span={12}>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model={ this.teamForm.phone } placeholder="请输入手机号" clearable />
            </el-form-item>
          </el-col>
          <el-col span={12}>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model={ this.teamForm.email } placeholder="请输入邮箱" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row gutter={30}>
          <el-col span={12}>
            <el-form-item label="会员属性" prop="memberLevelId">
              <el-select v-model={this.teamForm.memberLevelId} placeholder="请选择会员属性" clearable style="width: 100%">
                <el-option label="免费团体会员" value={5} />
                <el-option label="付费团体会员" value={6} />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  }

  render(): JSX.Element {
    return (() => {
      switch (this.type) {
        case 'detail':
          return <MoreVipDetail />
        case 'see':
          return <TeamDetail />
        default:
          return <div class="more_vip">
          <Card title="团体会员查询" class="more_vip-card">
            <Search class="more_vip-search" list={ this.searchList } onSearch={this.onSearch} />
          </Card>
          <Card title="会员信息">
            <div class="more_vip-search">
              <Button list={this.buttonList} onClick={ this.buttonClick } />
              {this.tableDom()}
              <Pagination ref="pagination" total={ this.total } onSizeChange={ this.getList } onCurrentChange={ this.getList } />
            </div>
          </Card>
          {this.type && this.dialogDom()}
        </div>
      }
    })()
  }
  mounted(): void {
    this.getList()
  }
}
